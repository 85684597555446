/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import kadane from "../../../../images/Algorithms/Kadane.webp";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    h2: "h2",
    pre: "pre",
    code: "code",
    blockquote: "blockquote",
    h3: "h3",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "maximum-subarray-sum",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#maximum-subarray-sum",
    "aria-label": "maximum subarray sum permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Maximum Subarray Sum"), "\n", React.createElement(_components.p, null, "in an array using ", React.createElement(_components.strong, null, "Kadane’s Algorithm"), " in Rust"), "\n", React.createElement(_components.h2, {
    id: "problem-statement",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#problem-statement",
    "aria-label": "problem statement permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Problem Statement"), "\n", React.createElement(_components.p, null, "Given an array of numbers, say ", React.createElement(_components.strong, null, "arr[]"), ", containing both ", React.createElement(_components.strong, null, "positive and negative elements"), ", you have to find the largest sum of the subarray of ", React.createElement(_components.strong, null, "arr[]"), "."), "\n", React.createElement(_components.p, null, "Subarray is defined as ", React.createElement(_components.strong, null, "contiguous"), " part of the original array, containing one or more element. For example, for array [1, 2, 3], [1, 2] is subarray while [1, 3] is not."), "\n", React.createElement(_components.p, null, "We have to find the largest  sum of all the subarray present in the given arr[]."), "\n", "\n", React.createElement("div", {
    style: {
      textAlign: 'center'
    }
  }, React.createElement("img", {
    src: kadane,
    width: "100%",
    alt: "Maximum Subarray Sum"
  })), "\n", React.createElement(_components.h2, {
    id: "naive-approach",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#naive-approach",
    "aria-label": "naive approach permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Naive Approach"), "\n", React.createElement(_components.p, null, "Naive or brute force approach is to find the sum of all the subarray and return the maximum of them. Function using this approach is"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn max_subarray_sum(arr : Vec<i128>) -> i128{\n\n    // it is not initialized to 0 because array can be all negative elements.\n    let mut max_sum = arr[0];\n\n    // Traverse through all the subarray\n    for i in 0..arr.len() {\n\n        // We use from i+1 to N inclusive\n        // Because j is ending range of the slice.\n        for j in i+1..arr.len()+1 {\n\n            // We find the sum of the subarray [i..j]\n            let mut sum = 0;\n            for k in i..j {\n                sum+=arr[k];\n            }\n\n            // Now compare the sum of this subarray with the max_sum\n            if max_sum < sum {\n                max_sum = sum;\n            }\n        }\n    }\n\n    return max_sum;\n}\n")), "\n", React.createElement(_components.p, null, "With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn max_subarray_sum(arr : Vec<i128>) -> i128{\n\n    // it is not initialized to 0 because array can be all negative elements.\n    let mut max_sum = arr[0];\n\n    // Traverse through all the subarray\n    for i in 0..arr.len() {\n\n        // We use from i+1 to N inclusive\n        // Because j is ending range of the slice.\n        for j in i+1..arr.len()+1 {\n\n            // We find the sum of the subarray [i..j]\n            let mut sum = 0;\n            for k in i..j {\n                sum+=arr[k];\n            }\n\n            // Now compare the sum of this subarray with the max_sum\n            if max_sum < sum {\n                max_sum = sum;\n            }\n        }\n    }\n\n    return max_sum;\n}\n\n// Driver Code\n\nfn main() {\n    let arr:Vec<i128> = vec![4, -5, 3, -2, 1, 5, -6, 3];\n    println!(\"{}\", max_subarray_sum(arr));\n}\n\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "7"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( n", React.createElement("sup", null, "3"), " )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "efficient-kadanes-algorithm",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#efficient-kadanes-algorithm",
    "aria-label": "efficient kadanes algorithm permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Efficient Kadane’s Algorithm"), "\n", React.createElement(_components.p, null, "Using Kadane’s Algorithm, we can find Maximum Subarray Sum in ", React.createElement(_components.strong, null, "Linear time complexity"), " and constant space complexity."), "\n", React.createElement(_components.h3, {
    id: "observation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#observation",
    "aria-label": "observation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Observation"), "\n", React.createElement(_components.p, null, "In Kadane’s Algorithm, we use a simple ", React.createElement(_components.strong, null, "Observation"), ", that if the sum of the elements upto a given element is negative, we can discard this sum.\nFor example, in array,"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "[4, ", React.createElement(_components.strong, null, "-5"), ", 3, -2, 1, 5, -6, 3]"), "\n"), "\n", React.createElement(_components.p, null, "We can see that sum of subarray [4, -5] is negative. So, we can easily discard this, because it will only decrease the sum of the elements. We can not include 4 without including -5. So, it is better to drop this subarray."), "\n", React.createElement(_components.p, null, "But in the array"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "[4, ", React.createElement(_components.strong, null, "-3"), ", 3, -2, 1, 5, -6, 3]"), "\n"), "\n", React.createElement(_components.p, null, "We can include the subarray [4, -3] because its sum is positive."), "\n", React.createElement(_components.h3, {
    id: "algorithm",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#algorithm",
    "aria-label": "algorithm permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Algorithm"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Initialise max_sum to any element of arr[] and current_sum to 0."), "\n", React.createElement(_components.li, null, "Add the first element to the current_sum."), "\n", React.createElement(_components.li, null, "If current_sum  is greater than the max_sum, set max_sum to current sum."), "\n", React.createElement(_components.li, null, "If current sum is less than 0, set current_sum = 0."), "\n", React.createElement(_components.li, null, "Repeat step 2 to 5 for each element of the arr[] and return the max_sum."), "\n"), "\n", React.createElement(_components.p, null, "Function using this approach is"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn max_subarray_sum(arr : Vec<i128>) -> i128{\n\n    // max sum is not initialized to 0 because array can be all negative elements.\n    let mut max_sum = arr[0];\n    let mut current_sum = 0;\n\n\n    for i in 0..arr.len() {\n        // We add element to the current sum first\n        // because we have to consider single element also\n        current_sum+=arr[i];\n\n        // If current sum is greater than max sum, it becomes max.\n        if current_sum>max_sum {\n            max_sum = current_sum;\n        }\n\n        // Discard the current sum if it is less than 0\n        if current_sum < 0 {\n            current_sum = 0;\n        }\n    }\n\n    return max_sum;\n}\n")), "\n", React.createElement(_components.p, null, "Use the same driver code."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "7"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( n )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Kadane's Algorithm is used to find the Maximum Subarray Sum in an array that may have positive as well as negative integers."), "\n", React.createElement(_components.p, null, "In this article, we saw the Kadane's Algorithm and also wrote the function to find the Maximum Subarray Sum in Rust Language."), "\n", React.createElement(_components.p, null, "Here is the optimized function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn max_subarray_sum(arr : Vec<i128>) -> i128{\n    let mut mx = arr[0];\n    let mut curr = 0;\n    for i in 0..arr.len() {\n        curr+=arr[i];\n        if curr>mx { mx = curr; }\n        if curr < 0 { curr = 0; }\n    }\n    return mx;\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Maximum Subarray Sum using Kadane’s Algorithm - Rust Programming",
    description: "Kadane's Algorithm is used to find the Maximum Subarray Sum in an array. In this article, we will see the Kadane's Algorithm and also write the function to find the Maximum Subarray Sum in the Rust Language"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
